import React, { Component } from "react"
import videoSliderStyles from "./videoSlider.module.scss"
import VideoSlide from "./VideoSlide"

class VideoSlider extends Component {
  constructor(props) {
    super(props)

    this.intervalTime = 4500
    this.slideTimeout = 0
    this.slideStyleTimeout = 0
    this.loadCounter = 1

    // Filter out projects that don't have a video
    this.projects = this.props.projects.filter(
      project => project.node.video !== null
    )

    shuffle(this.projects)

    // Add current key for every project in array to hide or show all projects (default: false)
    this.projects = this.projects.map(el => {
      var o = Object.assign({}, el)
      o.node.current = false
      return o
    })

    // Add videoState key for every project in array to load, play, reset/pause videos
    this.projects = this.projects.map(el => {
      var o = Object.assign({}, el)
      o.node.videoState = ""
      return o
    })

    // Add current to true for first project to show
    this.projects[0].node.current = true

    this.state = {
      projects: this.projects,
      isClient: false, //workaround two-pass-rendering due to bug in production build on netlify where on first load video and title of the project don't match (https://github.com/gatsbyjs/gatsby/issues/17914)
    }
  }

  componentDidMount() {
    this.setState({ isClient: true })
    this.mounted = true // Antipattern. Necessary because componentDidMount calls function which needs to be cancelled by componentWillUnmount
    var projects = this.state.projects

    //Play first video
    projects[0].node.videoState = "play"
    // this.loadCounter++

    //Load second video
    projects[1].node.videoState = "load"
    this.loadCounter++

    this.setState({ projects: projects })

    this.slideTimeout = setTimeout(this.nextSlide, this.intervalTime)
  }

  componentWillUnmount() {
    this.mounted = false
    var projects = this.state.projects
    projects = projects.map(project => {
      return (project.node.videoState = "reset")
    })
    this.setState({ projects: projects })
    clearTimeout(this.slideTimeout)
    this.slideTimeout = 0
    clearTimeout(this.slideStyleTimeout)
    this.slideStyleTimeout = 0
  }

  nextSlide = () => {
    if (this.mounted === true) {
      // Find Index of current project and get projects
      let currentIndex = this.state.projects.findIndex(
        project => project.node.current === true
      )

      let updatedProjects = this.state.projects

      // Set current project with index to false (=not current)

      updatedProjects[currentIndex].node.current = false

      // Set next project to current or first if end of array is reached
      if (currentIndex < updatedProjects.length - 1) {
        updatedProjects[currentIndex + 1].node.current = true
        updatedProjects[currentIndex + 1].node.videoState = "play"
      } else {
        updatedProjects[0].node.current = true
        updatedProjects[0].node.videoState = "play"
      }

      // Set state with updated Projects states
      this.setState({ projects: updatedProjects })

      // Get new current index
      currentIndex = this.state.projects.findIndex(
        project => project.node.current === true
      )

      // Load videos until all have been loaded
      if (this.loadCounter < this.state.projects.length) {
        updatedProjects = this.state.projects
        updatedProjects[currentIndex + 1].node.videoState = "load"
        this.setState({ projects: updatedProjects })
        this.loadCounter++
      }

      this.slideStyleTimeout = setTimeout(() => {
        updatedProjects = this.state.projects
        if (currentIndex > 0) {
          updatedProjects[currentIndex - 1].node.videoState = "reset"
          this.setState({ projects: updatedProjects })
        } else if (currentIndex === 0) {
          updatedProjects[updatedProjects.length - 1].node.videoState = "reset"
          this.setState({ projects: updatedProjects })
        }
      }, 500)

      this.slideTimeout = setTimeout(this.nextSlide, this.intervalTime)
    }
  }

  preload = index => {
    if (index === 0) {
      return "auto"
    }
    return "none"
  }

  render() {
    return (
      <React.Fragment key={this.state.isClient}>
        <div className={videoSliderStyles.slider}>
          {this.state.projects.map((project, index) => {
            return (
              <VideoSlide
                key={project.node.id}
                name={project.node.name}
                projectSlug={project.node.slug}
                videoUrl={project.node.video.file.url}
                current={project.node.current}
                videoState={project.node.videoState}
                preload={this.preload(index)}
              />
            )
          })}
        </div>
      </React.Fragment>
    )
  }
}

export default VideoSlider

// Shuffles projects for random videos to appear
function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}
