import React, { Component } from "react"
import { Link } from "gatsby"
import videoSlideStyles from "./videoSlide.module.scss"

export class VideoSlide extends Component {
  constructor(props) {
    super(props)
    this.videoState = this.videoState.bind(this)
  }

  slideStyle = () => {
    if (this.props.current === false) {
      return videoSlideStyles.slide
    } else if (this.props.current === true) {
      return `${videoSlideStyles.slide} ${videoSlideStyles.current}`
    }
  }

  tabIndex = () => {
    if (this.props.current === false) {
      return "-1"
    } else if (this.props.current === true) {
      return "0"
    }
  }

  videoState = () => {
    switch (this.props.videoState) {
      case "":
        break
      case "load":
        this.refs.vidRef.load()
        break
      case "play":
        this.refs.vidRef.play()
        break
      case "reset":
        this.refs.vidRef.pause()
        this.refs.vidRef.currentTime = 0
        break
      default:
    }
  }

  componentDidMount() {
    this.videoState()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.videoState !== this.props.videoState) {
      this.videoState()
    }
  }

  render() {
    return (
      <div className={this.slideStyle()}>
        <video
          ref="vidRef"
          muted
          loop
          playsInline
          className={videoSlideStyles.video}
          preload={this.props.preload}
          src={this.props.videoUrl}
        />
        <div className={videoSlideStyles.content}>
          <Link tabIndex="-1" to={`/projekte/${this.props.projectSlug}`}>
            <h2 tabIndex="-1">{this.props.name}</h2>
            <button tabIndex={this.tabIndex()}>Projekt ansehen</button>
          </Link>
        </div>
      </div>
    )
  }
}

export default VideoSlide
