import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import VideoSlider from "../components/video-slider/VideoSlider"

import Layout from "../components/layout"

import SEO from "../components/seo"

const IndexPage = () => {
  const {
    allContentfulProject: { edges },
  } = useStaticQuery(
    graphql`
      query {
        allContentfulProject {
          edges {
            node {
              id
              name
              slug
              video {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="Home" />
      <VideoSlider projects={edges} />
    </Layout>
  )
}

export default IndexPage
